'use strict';
import sxQuery from '../sxQuery/sxQuery';
import Logger from '../global/Logger';
import ThemeColorBuilder from '../siteSearch/styles/ThemeColorBuilder';
import TemplateRenderer from '../global/TemplateRenderer';
import ProductFinder from '../siteSearch/components/ProductFinder';
import StringHelper from '../global/StringHelper';
import Icons from '../siteSearch/components/Icons';
import SxQueryUtils from '../sxQuery/SxQueryUtils';
import MediaQueryMatchType from '../sxQuery/MediaQueryMatchType';
import SearchSuggest from '../siteSearch/model/SearchSuggest';
var ENTITY_MAP = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;'
};
var makeCssKey = function (key) { return key.replace(/[ "§$%&/(){}+*,.;|:]/g, '_').toLowerCase(); };
var escapeHtml = function (string) { return String(string).replace(/[&<>"'/]/g, function (s) { return ENTITY_MAP[s]; }); };
var getHref = function (selectable) {
    var $selectable = sxQuery(selectable);
    var href;
    try {
        // @ts-ignore
        href = $selectable.find('a:first').attr('href');
    }
    catch (err) {
        // ccl
    }
    if (href === undefined) {
        // @ts-ignore
        href = $selectable.data('href');
    }
    return href;
};
var getContent = function (selectable) {
    var $selectable = sxQuery(selectable);
    // @ts-ignore
    var content = $selectable.find('.unibox__search-content:first').text();
    if (content === undefined || content.length === 0) {
        // @ts-ignore
        content = $selectable.data('content');
    }
    if (content === undefined) {
        content = '';
    }
    return content;
};
var UniBox = /** @class */ (function () {
    function UniBox(searchBox, settings) {
        var context = settings.context;
        this.id = context !== undefined ? context.uniboxId : 'unibox-suggest-box';
        this.specialId = context !== undefined ? context.specialUniboxId : 'unibox-suggest-box-special';
        this.specialSearchBoxId = context !== undefined ? context.specialSearchBoxId : 'unibox-special-searchbox';
        this.uniboxControlsId = context !== undefined ? context.uniboxControlsId : 'unibox-controls-description';
        this.uniboxStatusMessageId = context !== undefined ? context.uniboxStatusMessageId : 'unibox-status-message';
        this.uniboxInvisibleId = context !== undefined ? context.uniboxInvisibleId : 'unibox-invisible';
        this.specialUniboxWrapId = context !== undefined ? context.specialUniboxWrapId : 'unibox-special';
        this.specialUniboxBackgroundId = context !== undefined ? context.specialUniboxBackgroundId : 'unibox-special-transition-background';
        this.specialUniboxHiddenContentId = context !== undefined ? context.specialUniboxHiddenContentId : 'unibox-special-hidden-content';
        if (settings.trackingCallbacks === undefined) {
            settings.trackingCallbacks = {};
        }
        if (settings.showOnMobile === undefined) {
            settings.showOnMobile = true;
        }
        if (settings.viewKeyMappings === undefined) {
            settings.viewKeyMappings = {};
        }
        var sms = settings.specialMobileSuggest;
        sms.breakpoint = sms.breakpoint || 768;
        sms.placeholder = sms.placeholder || '';
        sms.customTopHtml = sms.customTopHtml || '';
        sms.animateTransitions = sms.animateTransitions && !SxQueryUtils.prefersReducedMotion();
        sms.searchBoxPlaceholder = sms.searchBoxPlaceholder || 'Search';
        sms.autoHide = sms.autoHide !== undefined ? sms.autoHide : true;
        this.headingElement = "h" + Math.min(Math.max(1, settings.accessibility.headingLevel || 4), 6);
        if (settings.groupCta === undefined) {
            settings.groupCta = {};
        }
        if (settings.groupCta.show === undefined) {
            settings.groupCta.show = false;
        }
        this.searchBox = searchBox;
        this.settings = settings;
        this.selectedEntryIndex = -1;
        this.searchBoxParent = settings.searchBoxContainer;
        this.keepSuggests = false;
        this.suggestionsVisible = false;
        this.isHistoryVisible = false;
        this.ivfWords = [];
    }
    UniBox.prototype.getExistingUnibox = function () {
        return sxQuery("#" + this.id);
    };
    UniBox.prototype.getExistingSpecialUnibox = function () {
        return sxQuery("#" + this.specialId);
    };
    UniBox.prototype.getExistingSpecialSearchbox = function () {
        return sxQuery("#" + this.specialSearchBoxId);
    };
    UniBox.prototype.getExistingUniboxInvisible = function () {
        return sxQuery("#" + this.uniboxInvisibleId);
    };
    UniBox.prototype.getExistingUniboxSpecialWrap = function () {
        return sxQuery("#" + this.specialUniboxWrapId);
    };
    UniBox.prototype.init = function () {
        var _this = this;
        if (sxQuery('head #unibox-css').length === 0 && this.settings.defaultCss) {
            // eslint-disable-next-line global-require
            var css = require('../../sass/siteSearch/unibox.scss');
            sxQuery('head').append("<style id=\"unibox-css\" type=\"text/css\">" + css + "</style>");
            if (this.settings.themeColor !== undefined) {
                sxQuery('body').append("<style id=\"unibox-theme-color\">" + ThemeColorBuilder('unibox', this.settings.themeColor, undefined, "#" + this.id).build() + "</style>");
            }
        }
        this.searchBox.off('focus.unibox, focusout.unibox, keydown.unibox, keyup.unibox'); // remove all bound event listeners
        var _a = this, searchBox = _a.searchBox, settings = _a.settings;
        if (settings.searchButtonSelector !== undefined) {
            var searchButtons = this.searchBoxParent.find(settings.searchButtonSelector);
            if (searchButtons.length === 1) {
                if (Math.abs(searchButtons.offset().top - searchBox.offset().top) < 5
                    && searchButtons.offset().left > searchBox.offset().left) { // similar y level and search button to the right of the search box
                }
                this.searchButton = searchButtons;
            }
        }
        // insert necessary values for inputfield
        searchBox.attr('autocomplete', 'off');
        // append invisible label for search field (if no label is defined)
        var accessibility = settings.accessibility;
        var searchFieldLabel = accessibility.searchFieldLabel;
        if (searchFieldLabel && searchBox.attr('aria-label') === null && (!searchBox.attr('id') || sxQuery("label[for='" + searchBox.attr('id') + "']").length === 0)) {
            if (!searchBox.attr('id')) {
                // @ts-ignore
                if (!window.uniboxCounter) {
                    // @ts-ignore
                    window.uniboxCounter = 0;
                }
                // @ts-ignore
                window.uniboxCounter++;
                // @ts-ignore
                searchBox.attr('id', "unibox-search-box-" + window.uniboxCounter);
            }
            searchBox.attr('aria-label', searchFieldLabel);
        }
        searchBox.on('keyup.unibox', function (e) {
            _this.resetSuggests(e);
        });
        // handling the placeholder
        // if placeholder is not undefined and length > 0 go on, else no placeholder at all
        var placeholder = settings.placeholder;
        if (placeholder !== undefined && placeholder.length > 0 && searchBox.data('ss360KeepPlaceholder') !== 'true') {
            searchBox.attr('placeholder', placeholder);
        }
        if (sxQuery("#" + this.uniboxControlsId).length === 0) {
            var controlDescriptionText = accessibility.srSuggestBoxControlDescription;
            var controlDescription = "<span id='" + this.uniboxControlsId + "' style='" + SxQueryUtils.srOnlyCss + "' class='unibox-sr-only' tabindex='-1'>" + controlDescriptionText + "</span>";
            searchBox.parent().append(controlDescription);
        }
        if (sxQuery("#" + this.uniboxStatusMessageId).length === 0) {
            // add aria-live region to announce search results
            var ariaLive = sxQuery("<span id=\"" + this.uniboxStatusMessageId + "\" style=\"" + SxQueryUtils.srOnlyCss + "\" tabindex=\"-1\" aria-live=\"polite\" aria-atomic=\"true\" role=\"status\" class=\"unibox-sr-only\">");
            searchBox.parent().append(ariaLive);
        }
        searchBox.attr('role', 'combobox');
        searchBox.attr('aria-describedby', this.uniboxControlsId);
        searchBox.attr('aria-owns', this.id);
        searchBox.attr('aria-controls', this.id);
        searchBox.attr('aria-expanded', 'false');
        // position and size the suggest box
        this.getExistingUnibox().remove();
        var suggestBox = sxQuery("<div id=\"" + this.id + "\" class=\"unibox\" role=\"listbox\" aria-label=\"Search Suggestions\"></div>");
        this.suggestBox = suggestBox;
        this.searchBoxParent.prepend(this.suggestBox);
        var pos = this.searchBoxParent.css('position');
        if (pos !== 'absolute') {
            this.searchBoxParent.css('position', 'relative');
        }
        // @ts-ignore
        var borderSize = (suggestBox.css('border-width') || '0px').replace('px', '');
        suggestBox.css('min-width', searchBox.outerWidth() - 2 * borderSize);
        if (settings.maxWidth !== 'auto') {
            // @ts-ignore
            suggestBox.css('max-width', settings.maxWidth - 2 * borderSize);
        }
        // add event listeners
        searchBox.on('keydown.unibox', function (event) {
            _this.scrollList(event);
        });
        searchBox.on('keydown.unibox', function (event) {
            _this.throttle(function () {
                _this.searchSuggest(event);
            }, settings.throttleTime);
        });
        if (!SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Max, 767)) { // on small devices we do not listen for focusout event - otherwise suggestions might be closed (on iPhone) when clicking the done virtual keyboard button
            searchBox.on('focusout.unibox', function (e) {
                if (_this.keepSuggests) {
                    return;
                }
                _this.hideSuggests(e);
                if (settings.callbacks.blur !== undefined && !_this.shouldUseSpecialSuggestBox()) {
                    settings.callbacks.blur.call(_this, e, searchBox.val(), true);
                }
            });
        }
        var specialMobileSuggest = settings.specialMobileSuggest;
        if (specialMobileSuggest.trigger !== undefined) {
            sxQuery(specialMobileSuggest.trigger).click(function () {
                _this.showSpecialSuggest();
            });
        }
        var moveUnibox = settings.hasMultipleSearchBoxes;
        searchBox.on('focus.unibox', function (e) {
            e = e || window.event;
            e.stopPropagation();
            if (e.preventSuggests !== true) {
                var showMobileLayer = _this.shouldUseSpecialSuggestBox();
                if (showMobileLayer) {
                    _this.showSpecialSuggest();
                    _this.syncSpecialSearchBoxQuery();
                }
                else if (moveUnibox) {
                    // move unibox to current sb context (if multiple search boxes and not using special mobile)
                    var isUniboxFirstLevelChild = function (parentNode) {
                        if (parentNode.children) {
                            for (var i = 0; i < parentNode.children.length; i++) {
                                if (parentNode.children[i].getAttribute('id') === _this.id) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    };
                    var parent = sxQuery(e.target).parent();
                    if (parent.length > 0 && (parent.find("#" + _this.id).length === 0 || !isUniboxFirstLevelChild(parent.get()[0]))) {
                        parent.prepend(_this.getExistingUnibox());
                        parent.append(_this.getExistingUniboxInvisible());
                    }
                }
                if (_this.isFullWidth()) {
                    var offsetTop = sxQuery(e.target).offset().top - sxQuery('body').offset().top;
                    sxQuery('html, body').animateScrollTop(offsetTop, 2 * settings.animationSpeed);
                    _this.getExistingUnibox().addClass('unibox--fullwidth');
                }
                else {
                    _this.getExistingUnibox().removeClass('unibox--fullwidth');
                }
                var curSbVal = sxQuery(e.target).val();
                if (curSbVal.length > 0) {
                    _this.searchSuggest({
                        keyCode: (_this.currentInput === curSbVal ? -1 : -2)
                    }); // should we only show the suggest box or fetch new suggests?
                }
                else if (settings.showProductFinder && settings.productFinderConfig !== undefined && ProductFinder.exists(settings.productFinderConfig)) {
                    var $suggestBox_1 = _this.getExistingUnibox();
                    if ($suggestBox_1.find('.ss360-product-finder').length === 0) {
                        $suggestBox_1.html('');
                        ProductFinder.loadAndRender($suggestBox_1, settings.productFinderConfig, _this.settings.context, function () {
                            $suggestBox_1.find('.ss360-product-finder').on('mousedown', function () {
                                _this.keepSuggests = true;
                            }).on('mouseup', function () {
                                _this.keepSuggests = false;
                            });
                            _this.resizeAndReposition();
                            if (!_this.shouldUseSpecialSuggestBox()) { // do nothing for mobile suggest box
                                _this.show($suggestBox_1);
                            }
                        });
                    }
                    else {
                        _this.resizeAndReposition();
                        if (!_this.shouldUseSpecialSuggestBox()) { // do nothing for mobile suggest box
                            _this.show($suggestBox_1);
                        }
                    }
                }
                else if (settings.emptyQuerySuggests !== undefined) {
                    // @ts-ignore
                    _this.updateSuggestBox(settings.emptyQuerySuggests, undefined, true);
                }
                else if (settings.maxHistorySuggestions !== undefined && settings.maxHistorySuggestions > 0) {
                    // @ts-ignore
                    var historyEntries = _this.settings.context !== undefined
                        ? _this.settings.context.readObject('unibox_search_history', []) : SxQueryUtils._readObject('unibox_search_history', []);
                    if (historyEntries.length > 0) {
                        var historySuggestionArr = [];
                        for (var i = 0; i < settings.maxHistorySuggestions && i < historyEntries.length; i++) {
                            historySuggestionArr.push({
                                name: StringHelper.escapeHtml(historyEntries[i]),
                                history: true
                            });
                        }
                        var historySuggestionObj = {};
                        historySuggestionObj[settings.historySuggestionLabel] = historySuggestionArr;
                        // @ts-ignore
                        _this.updateSuggestBox({
                            suggests: historySuggestionObj
                        }, undefined, true, true);
                    }
                }
            }
            if (settings.callbacks.focus !== undefined) {
                try {
                    settings.callbacks.focus.call(_this, e, sxQuery(e.target).val());
                }
                catch (ex) {
                    Logger.warn(ex);
                }
            }
        });
        suggestBox.mouseenter(function () {
            suggestBox.find('.unibox__selectable--active').removeClass('.unibox__selectable--active');
        });
        // click outside of suggest div closes it
        sxQuery('html').click(function (e) {
            if (!_this.suggestionsVisible)
                return;
            try {
                var id = e !== undefined ? sxQuery(e.target).attr('id') : undefined;
                if (id === searchBox.attr('id') || id === _this.id || sxQuery(e.target).parents("#" + _this.id).length > 0) {
                    return;
                }
            }
            catch (ex) {
                Logger.warn(ex);
            }
            if (suggestBox.hasClass('unibox--active')) {
                _this.hideSuggests(e);
            }
        });
        // special for tab key (because if shift+tab when getting focus back)
        searchBox.on('keydown.unibox', function (e) {
            e = e || window.event;
            var keyCode = e.keyCode || e.which;
            if (keyCode === 9) {
                _this.hideSuggests(e);
            }
        });
        if (!SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Max, 767)) { // on small devices we do not listen for focusout event - otherwise suggestions might be closed (on iPhone) when clicking the done virtual keyboard button
            searchBox.on('focusout.unibox', function (e) {
                if (_this.keepSuggests) {
                    return;
                }
                e = e || window.event;
                setTimeout(function () {
                    if (sxQuery(document.activeElement).parents("#" + _this.id).length === 0) {
                        _this.hideSuggests(e);
                    }
                }, 10);
            });
        }
        // disable click event propagation to html element
        if (settings.disableEventPropagationHtml) {
            searchBox.click(function (event) {
                event.stopPropagation();
            });
            suggestBox.click(function (event) {
                event.stopPropagation();
            });
        }
        // copy search box styles to an invisible element so we can determine the text width
        this.getExistingUniboxInvisible().remove();
        var invisible = sxQuery("<div id=\"" + this.uniboxInvisibleId + "\" class=\"unibox__invisible\">&nbsp;<span>&nbsp;</span></div>");
        this.searchBoxParent.append(invisible);
        if (settings.instantVisualFeedback === 'none') {
            this.getExistingUniboxInvisible().css('display', 'none');
        }
        this.initSpecialSuggestBox();
    };
    UniBox.prototype.resetSuggests = function (event) {
        var _this = this;
        // @ts-ignore
        event = event || window.event;
        var settings = this.settings;
        var minChars = settings.minChars, emptyQuerySuggests = settings.emptyQuerySuggests, loaderSelector = settings.loaderSelector;
        if (event !== undefined) {
            var keyCode = event.keyCode || event.which;
            if (keyCode !== undefined && keyCode === 0 && this.savedKeyCodeOnKeyDown !== undefined) {
                keyCode = this.savedKeyCodeOnKeyDown;
                this.savedKeyCodeOnKeyDown = undefined;
            }
            var inputText = this.getSearchBox().val();
            // hide if escape, or enter was pressed
            if (keyCode === 27 || keyCode === 13 || (inputText.length < minChars && emptyQuerySuggests === undefined && !this.isHistoryVisible)) {
                this.savedKeyCodeOnKeyDown = undefined; // reset
                this.hideSuggests(event);
                var enterCallback = settings.callbacks.enter;
                var autoHide = settings.specialMobileSuggest.autoHide;
                if (keyCode === 13 && enterCallback !== undefined && this.selectedEntryIndex === -1 && !settings.ignoreEnter) {
                    if (this.shouldUseSpecialSuggestBox() && loaderSelector !== undefined && autoHide === false) {
                        sxQuery(loaderSelector).css('z-index', '9999999');
                    }
                    enterCallback.call(this, inputText, undefined, this.shouldUseSpecialSuggestBox() && autoHide === false ? function () {
                        _this.hideSpecialSuggest();
                        if (loaderSelector !== undefined) {
                            sxQuery(loaderSelector).css('z-index', '');
                        }
                    } : undefined);
                    if (autoHide !== false) {
                        this.hideSpecialSuggest();
                    }
                }
                this.selectedEntryIndex = -1;
            }
        }
        else {
            this.hideSuggests(event);
            this.selectedEntryIndex = -1;
        }
    };
    UniBox.prototype.throttle = function (f, delay) {
        clearTimeout(this.timer);
        // @ts-ignore
        this.timer = setTimeout(function () {
            f();
        }, delay);
    };
    UniBox.prototype.hideSuggests = function (e) {
        this.suggestionsVisible = false;
        var $suggestBox = this.getExistingUnibox();
        this.searchBox.attr('aria-expanded', 'false');
        var statusElement = sxQuery("#" + this.uniboxStatusMessageId);
        var contentText = this.settings.accessibility.srSuggestionsHiddenText;
        if (statusElement.text() !== contentText) {
            statusElement.html(contentText);
        }
        this.searchBox.removeAttribute('aria-activedescendant');
        var blurCallback = this.settings.callbacks.blur;
        // only call blur callback here when suggest box was really shown
        if (blurCallback !== undefined && $suggestBox.hasClass('unibox--active')) {
            try {
                blurCallback.call(this, e, this.searchBox.val(), false);
            }
            catch (ex) {
                Logger.warn(ex);
            }
        }
        $suggestBox.removeClass('unibox--active');
        if (!this.shouldUseSpecialSuggestBox()) { // do nothing for mobile suggest box
            $suggestBox.slideUp(this.settings.animationSpeed);
        }
        this.clearIvf();
    };
    // highlight search words
    UniBox.prototype.highlightSearchWords = function (string, searchString) {
        if (!this.settings.highlight || string === undefined || searchString === undefined) {
            return string;
        }
        var words = searchString.replace(/[^a-zA-Z0-9äöüÄÖÜßçâêîôûàèùëïěščřžýáíéťňÇÂÊÎÔÛÀÈÙËÏĚŠČŘŽÝÁÍÉŤŇ]|\s+|\r?\n|\r/gmi, ' ')
            .replace(/[^a-zA-Z0-9äöüÄÖÜßçâêîôûàèùëïěščřžýáíéťňÇÂÊÎÔÛÀÈÙËÏĚŠČŘŽÝÁÍÉŤŇ]/g, ' ').split(' ');
        // sort words by length, longest first
        words.sort(function (a, b) { return b.length - a.length; }); // ASC -> a - b; DESC -> b - a
        var markers = {};
        SxQueryUtils.each(words, function (idx, word) {
            if (word.length < 2) {
                return;
            }
            var matches = string.match(new RegExp("((" + word + ")(?!#<##|-\\d+#<##))(?!.*\\1)", 'gi'));
            if (matches != null) {
                for (var i = 0; i < matches.length; i++) {
                    var match = matches[i];
                    var matchEsc = match.replace(/[-[\]/{}()*+?.^$|]/g, '\\$&');
                    string = string.replace(new RegExp("(" + matchEsc + ")(?!#<##|-\\d+#<##)", 'g'), "##>#" + idx + "-" + i + "#<##");
                    markers["##>#" + idx + "-" + i + "#<##"] = "<span class=\"unibox__highlight\">" + match + "</span>";
                }
            }
        });
        var reversedMarkerKeys = Object.keys(markers).reverse();
        for (var i = 0; i < reversedMarkerKeys.length; i++) {
            var singleMarker = reversedMarkerKeys[i];
            var replacement = markers[singleMarker];
            string = string.replace(new RegExp(singleMarker, 'gi'), replacement);
        }
        return string;
    };
    UniBox.prototype.onGroupCtaClick = function (e, query, contentGroup) {
        this.resetSuggests();
        this.hideSuggests(e);
        this.hideSpecialSuggest();
        this.settings.groupCta.callback(query, contentGroup);
    };
    UniBox.prototype.getSearchBox = function () {
        if (this.shouldUseSpecialSuggestBox()) {
            var uniboxSpecial = this.getExistingUniboxSpecialWrap();
            if (!uniboxSpecial || uniboxSpecial.length === 0) {
                this.initSpecialSuggestBox();
            }
            return this.getExistingSpecialSearchbox();
        }
        return this.searchBox;
    };
    UniBox.prototype.getSuggestBox = function () {
        if (this.shouldUseSpecialSuggestBox()) {
            if (this.getExistingSpecialUnibox().length === 0) {
                this.initSpecialSuggestBox();
            }
            return this.getExistingSpecialUnibox();
        }
        var box = this.getExistingUnibox();
        if (box.length === 0) {
            this.suggestBox = sxQuery("<div id=\"" + this.id + "\" class=\"unibox\" role=\"listbox\" aria-label=\"Search Suggestions\"></div>");
            this.searchBoxParent.prepend(this.suggestBox);
            box = this.suggestBox;
        }
        return box;
    };
    UniBox.prototype.updateSuggestBox = function (data, querySubmitTimestamp, isEmptyQuerySuggest, isSearchHistory) {
        var _this = this;
        if (isEmptyQuerySuggest === void 0) { isEmptyQuerySuggest = false; }
        if (isSearchHistory === void 0) { isSearchHistory = false; }
        var $suggestBox = this.getSuggestBox();
        var specialSearchBox = this.getExistingSpecialSearchbox();
        if (data !== undefined && this.lastKeyCode !== 13 && data.suggests === undefined) { // product search fallbacks
            if (data.searchResults !== undefined) {
                data.suggests = data.searchResults.reduce(function (acc, entry) {
                    acc[entry.name] = entry.results;
                    return acc;
                }, {});
            }
        }
        // don't do anything if the last key was enter
        if (this.lastKeyCode === 13 || data === undefined || data.suggests === undefined) {
            this.hideSuggests();
            this.selectedEntryIndex = -1;
            return;
        }
        // @ts-ignore
        var searchString = this.getSearchBox().val();
        var searchStringXss = escapeHtml(searchString);
        // // fill the box
        $suggestBox.html('');
        $suggestBox.removeClass('unibox--empty');
        this.getExistingSpecialUnibox().html('');
        var settings = this.settings;
        // find out whether we have something to show in the first place
        var showSuggestBox = false;
        if (!settings.showOnMobile && SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Max, 767)) {
            return;
        }
        // set state if no suggestions notice is visible
        var showNoSuggestions = false;
        // suggest
        var suggestOrderToUse = Object.keys(data.suggests);
        var suggestOrder = settings.suggestOrder, suggestTemplate = settings.suggestTemplate, viewKeyMappings = settings.viewKeyMappings;
        if (suggestOrder && suggestOrder.length > 0) {
            suggestOrderToUse = settings.suggestOrder;
            SxQueryUtils.each(Object.keys(data.suggests), function (i, o) {
                if (suggestOrderToUse.indexOf(o) < 0)
                    suggestOrderToUse.push(o);
            });
        }
        var totalCount = 0;
        if (this.shouldUseSpecialSuggestBox()) {
            specialSearchBox.removeAttribute('aria-activedescendant');
        }
        else {
            this.searchBox.removeAttribute('aria-activedescendant');
        }
        var SuggestRenderer = suggestTemplate !== undefined && suggestTemplate.template !== undefined ? new TemplateRenderer(suggestTemplate) : undefined;
        var suggestionContentGroups = [];
        SxQueryUtils.each(suggestOrderToUse, function (idx, key) {
            var groupKey = key;
            var values = data.suggests[key];
            if (!values || values.length === 0) {
                return;
            }
            // check if other arrays have content, if this suggestion-block is the only one, mark it via css class
            var countOtherSuggestionValues = 0;
            SxQueryUtils.each(suggestOrderToUse, function (index, sKey) {
                if (!values || key === sKey || values.length === 0) {
                    return;
                }
                countOtherSuggestionValues += values.length;
            });
            if (countOtherSuggestionValues > 0 && key === '_') {
                key = viewKeyMappings._ || '_';
            }
            var cssKey = makeCssKey(key);
            var labelledBy = '';
            if (cssKey !== '_') {
                labelledBy = "aria-labelledby=\"unibox-suggest-cluster-heading-" + cssKey + "\"";
            }
            var suggestSet = sxQuery("<section class=\"unibox-n-section unibox__cluster unibox__cluster--" + cssKey + " unibox__cluster--" + values.length + "-entries\" " + labelledBy + "></section>");
            var visibleKey = key;
            if (key.replace(/_/, '').length > 0 && values.length > 0) {
                if (visibleKey in viewKeyMappings) {
                    visibleKey = viewKeyMappings[visibleKey];
                    if (visibleKey === undefined) {
                        visibleKey = '';
                    }
                }
                if (visibleKey.length > 0) {
                    var keyNode = sxQuery("<" + _this.headingElement + " class=\"unibox__suggest-heading\" id=\"unibox-suggest-cluster-heading-" + cssKey + "\">" + visibleKey + "</" + _this.headingElement + ">");
                    suggestSet.append(keyNode);
                }
            }
            // @ts-ignore
            var groupHasImages = settings.showImagesSuggestions && values.reduce(function (acc, val) { return acc || (val.image !== undefined && val.image !== null); }, false);
            if (!groupHasImages) {
                suggestSet.addClass('unibox__cluster--no-img');
            }
            SxQueryUtils.each(values, function (index, suggest) {
                try {
                    if (suggest instanceof Array) {
                        suggest = suggest[0];
                    }
                }
                catch (ex) {
                    // ccl
                }
                suggestionContentGroups.push(key);
                var suggestLine = '';
                if (SuggestRenderer !== undefined) {
                    if (suggest.name !== undefined && suggest.name !== '') {
                        suggest.highlightedName = _this.highlightSearchWords(suggest.name, searchStringXss);
                    }
                    if (suggest.content !== undefined && suggest.content !== '') {
                        suggest.highlightedContent = _this.highlightSearchWords(suggest.content, searchStringXss);
                    }
                    suggestLine = SuggestRenderer.render(new SearchSuggest(suggest), key, false, true);
                }
                else {
                    suggestLine += '<div class="unibox__selectable" aria-selected="false" role="option">';
                    var isResizedImage = false;
                    if (suggest.image !== undefined && suggest.image !== null && settings.showImagesSuggestions) {
                        var imageUrl = void 0;
                        if (suggest.image.length === 0 && settings.missingErrorImage) {
                            imageUrl = settings.missingErrorImage;
                        }
                        else if (suggest.image.length === 0 || suggest.image.indexOf('/') === 0 || suggest.image.indexOf('http') === 0) {
                            imageUrl = suggest.image;
                        }
                        else {
                            imageUrl = settings.ivfImagePath + suggest.image;
                        }
                        if (suggest.dataPoints !== undefined && suggest.dataPoints.length > 0) {
                            for (var i = 0; i < suggest.dataPoints.length; i++) {
                                var dataPoint = suggest.dataPoints[i];
                                if (dataPoint.key === 'suggestionImageUrl') {
                                    imageUrl = dataPoint.value;
                                    isResizedImage = true;
                                    break;
                                }
                            }
                        }
                        suggestLine += "<div class=\"unibox__img-container\"><img class=\"unibox__img\" src=\"" + imageUrl + "\"";
                        var img = new Image();
                        img.src = imageUrl;
                        if (!img.complete) {
                            suggestLine += ' style="display: none;" onload="this.style.display=null;"';
                            if (isResizedImage) {
                                suggestLine += " onerror=\"this.src='" + suggest.image + "';\"";
                            }
                        }
                        suggestLine += ' alt aria-hidden="true" role="presentation"/></div>';
                    }
                    suggestLine += '<div class="unibox__content-container">';
                    if (suggest.link !== undefined && suggest.link !== '') {
                        suggestLine += "<a class=\"unibox__search-content unibox__search-content--link\" href=\"" + suggest.link + "\" title=\"" + suggest.name + "\">";
                        suggestLine += _this.highlightSearchWords(suggest.name, searchStringXss);
                        suggestLine += '</a>';
                    }
                    else if (suggest.name !== undefined && suggest.name !== '') {
                        suggestLine += "<span class=\"unibox__search-content\">" + _this.highlightSearchWords(suggest.name, searchStringXss) + "</span>";
                    }
                    if (suggest.content !== undefined && suggest.content !== '') {
                        suggestLine += "<p class=\"unibox__result-content\">" + _this.highlightSearchWords(suggest.content, searchStringXss) + "</p>";
                    }
                    if (suggest.suggestionHtml !== undefined && suggest.suggestionHtml !== '') {
                        suggestLine += "<span class=\"unibox__search-content\">" + suggest.suggestionHtml + "</span>";
                    }
                    else if (suggest.html !== undefined) {
                        // no suggestionHtml but only HTML -> we don't show this empty result line
                        return;
                    }
                    var missedMatch_1 = false;
                    var extraHtmlFilled = void 0;
                    var templateLineCallback_1 = function (template, replacement) {
                        if (template === undefined || template.length === 0) {
                            return '';
                        }
                        var matches = template.match(/#(.*?)#/gi);
                        if (matches !== null) {
                            for (var i = 0; i < matches.length; i++) {
                                var match = matches[i];
                                if (match !== undefined && match.length > 0) {
                                    var variable = match.replace(/#/g, '');
                                    if (replacement === undefined && suggest.dataPoints !== undefined) {
                                        for (var d = 0; d < suggest.dataPoints.length; d++) {
                                            var dpo = suggest.dataPoints[d];
                                            if (dpo.key === variable) {
                                                replacement = dpo.value;
                                                break;
                                            }
                                        }
                                    }
                                    if (replacement === undefined) {
                                        missedMatch_1 = true;
                                    }
                                    else {
                                        var re = new RegExp(match, 'g');
                                        template = template.replace(re, replacement);
                                    }
                                }
                            }
                        }
                        return template;
                    };
                    if (isSearchHistory) {
                        extraHtmlFilled = "<button class=\"unibox__delete-history unibox-n-button\" title=\"Remove suggestion\">\n\t\t\t\t\t\t" + Icons.getSvgIcon(Icons.CROSS, '#626262', 'unibox__delete-history-icon') + "</button>";
                    }
                    else if (settings.dataPoints !== undefined) {
                        var usedKeys_1 = {};
                        var dataPoints_1 = settings.dataPoints;
                        var filledByKey_1 = (suggest.dataPoints || []).reduce(function (acc, dataPoint) {
                            if (dataPoints_1[dataPoint.key] !== undefined) {
                                if (acc[dataPoint.key] === undefined) {
                                    acc[dataPoint.key] = [];
                                }
                                var val = dataPoint.value;
                                if (dataPoints_1[dataPoint.key].formatValue !== undefined) {
                                    val = dataPoints_1[dataPoint.key].formatValue(val);
                                }
                                acc[dataPoint.key].push(templateLineCallback_1(dataPoints_1[dataPoint.key].html, val));
                                usedKeys_1[dataPoint.key] = true;
                            }
                            return acc;
                        }, {});
                        extraHtmlFilled = Object.keys(usedKeys_1).sort(function (a, b) {
                            // @ts-ignore
                            var posA = dataPoints_1[a].position !== undefined ? parseInt(dataPoints_1[a].position, 10) : -1;
                            // @ts-ignore
                            var posB = dataPoints_1[b].position !== undefined ? parseInt(dataPoints_1[b].position, 10) : -1;
                            if (posA === posB) {
                                return 0;
                            }
                            if (posA === -1) {
                                return 1;
                            }
                            if (posB === -1) {
                                return -1;
                            }
                            return posA - posB;
                        }).reduce(function (acc, dpKey) {
                            acc.push(filledByKey_1[dpKey].join(''));
                            return acc;
                        }, []).join('');
                    }
                    else if (settings.extraHtml !== undefined) {
                        var matches = settings.extraHtml.match(/#(.*?)#/gi);
                        if (matches != null) {
                            extraHtmlFilled = templateLineCallback_1(settings.extraHtml);
                        }
                    }
                    if (extraHtmlFilled !== undefined) {
                        if (missedMatch_1) {
                            extraHtmlFilled = extraHtmlFilled.replace(/#(.*?)#/gi, '');
                        }
                        suggestLine += "<div class=\"unibox__extra\">" + extraHtmlFilled + "</div>";
                    }
                    suggestLine += '</div>';
                    suggestLine += '<div class="unibox__ca" role="presentation"></div>';
                    suggestLine += '</div>';
                }
                if (settings.callbacks.line !== undefined) {
                    suggestLine = settings.callbacks.line(suggestLine, key, index, suggest);
                }
                var suggestNode = sxQuery(suggestLine);
                if (isSearchHistory) {
                    suggestNode.find('.unibox__delete-history').on('click', function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        suggestNode.remove();
                        // @ts-ignore
                        var historyEntries = _this.settings.context !== undefined
                            ? _this.settings.context.readObject('unibox_search_history', []) : SxQueryUtils._readObject('unibox_search_history', []);
                        var entryIdx = historyEntries.indexOf(StringHelper.unescapeHtml(suggest.name));
                        if (entryIdx !== -1) {
                            historyEntries.splice(entryIdx, 1);
                            if (_this.settings.context !== undefined) {
                                _this.settings.context.storeObject('unibox_search_history', historyEntries);
                            }
                            else {
                                SxQueryUtils._storeObject('unibox_search_history', historyEntries);
                            }
                        }
                        if (historyEntries.length === 0) {
                            _this.hideSuggests();
                        }
                        else {
                            _this.resizeAndReposition();
                        }
                    });
                    suggestNode.addClass('unibox__selectable--history');
                }
                suggestSet.append(suggestNode);
                showSuggestBox = true;
                totalCount++;
            });
            var groupCta = settings.groupCta;
            if (groupCta.show && !isEmptyQuerySuggest && groupCta.groupLabels[key] !== null) {
                var groupLabel = ((groupCta.groupLabels[key] || groupCta.label) || '').replace(/#GROUP#/g, groupCta.lowerCaseNames ? visibleKey.toLowerCase() : visibleKey);
                var ctaButton = sxQuery("<button class=\"unibox__selectable unibox__selectable--group-cta unibox-tc-c\" type=\"button\" data-ss360-group=\"" + groupKey + "\">" + groupLabel + "</button>");
                if (groupCta.callback !== undefined) {
                    ctaButton.on('click', function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        _this.onGroupCtaClick(e, data.query, groupKey);
                    });
                }
                suggestSet.append(ctaButton);
            }
            _this.getSuggestBox().append(suggestSet);
            if (settings.callbacks.postRender !== undefined) {
                settings.callbacks.postRender();
            }
        });
        // accessibility extension
        var accessibility = settings.accessibility, callbacks = settings.callbacks;
        var statusText = accessibility.srNoSuggestionsText;
        var showAllButton;
        if (totalCount > 0) {
            if (settings.viewAllLabel !== undefined && !this.shouldUseSpecialSuggestBox() && !isEmptyQuerySuggest) {
                showAllButton = sxQuery("<button class=\"unibox__selectable unibox__selectable--show-all unibox-n-button unibox-tc-c\"><span class=\"unibox__show-all-text unibox-tc-c\">" + settings.viewAllLabel + "</span>\n\t\t\t\t\t<i class=\"unibox__show-all-icon\">" + Icons.getSvgIcon(Icons.MAGNIFIER, settings.themeColor, undefined, 16, 16) + "</i></button>");
                showAllButton.on('click', function () {
                    // @ts-ignore
                    callbacks.enter(_this.getSearchBox().val(), showAllButton.get()[0]);
                });
                this.getSuggestBox().append(showAllButton);
            }
            statusText = (totalCount > 1 ? accessibility.srSuggestionsCountText : accessibility.srOneSuggestionText).split('#COUNT#').join(totalCount.toString());
            this.isHistoryVisible = isSearchHistory;
        }
        else {
            this.isHistoryVisible = false;
        }
        var statusElement = sxQuery("#" + this.uniboxStatusMessageId);
        if (statusElement.text() !== statusText) {
            statusElement.text(statusText);
        }
        if (callbacks.suggestsBuilt !== undefined && typeof callbacks.suggestsBuilt === 'function') {
            callbacks.suggestsBuilt($suggestBox, data);
        }
        // // update selectables for cursor navigation, use given order
        var selectablesScope = this.shouldUseSpecialSuggestBox() ? this.getExistingUniboxSpecialWrap() : this.searchBoxParent;
        this.selectables = selectablesScope.find('.unibox__selectable');
        if (settings.suggestSelectionOrder && settings.suggestSelectionOrder.length > 0) {
            var arr_1 = [];
            SxQueryUtils.each(settings.suggestSelectionOrder, function (idx, item) {
                arr_1 = arr_1.concat(selectablesScope.find(".unibox__cluster--" + makeCssKey(item) + ":first .unibox__selectable").get());
            });
            if (showAllButton !== undefined) {
                arr_1.push(showAllButton.get()[0]);
            }
            this.selectables = sxQuery(arr_1);
        }
        this.selectedEntryIndex = -1;
        // click handler on selectables
        this.selectables.each(function (selectable) {
            var $selectable = sxQuery(selectable);
            $selectable.off('click.selectableClick');
            $selectable.on('click.selectableClick', function (e) {
                var $target = sxQuery(e.target);
                if ($target.hasClass('unibox__selectable--group-cta'))
                    return;
                e.preventDefault();
                e.stopPropagation();
                // @ts-ignore
                var prevVal = _this.getSearchBox().val();
                var ctrlKey = e.ctrlKey;
                var q = getContent($selectable);
                var isShowAllButton = settings.viewAllLabel !== undefined && ($target.hasClass('unibox__selectable--show-all') || $target.parents('.unibox__selectable--show-all').length !== 0);
                var href = getHref($selectable);
                if (!isShowAllButton && !ctrlKey && (settings.setSearchBoxTextOnSelect || href === undefined)) {
                    _this.getSearchBox().val(q);
                }
                if (callbacks.enterResult !== undefined && !isShowAllButton) {
                    try {
                        var items = _this.getSuggestBox().find('.unibox__selectable');
                        var idx = SxQueryUtils.indexInNodeList(selectable, items.get());
                        var contentGroup = suggestionContentGroups[idx];
                        if (settings.trackingCallbacks.select !== undefined) {
                            var sugBox = _this.getSuggestBox();
                            var sField = _this.getSearchBox();
                            settings.trackingCallbacks.select(sField.get()[0], sugBox.get()[0], 
                            // @ts-ignore
                            e.target, isSearchHistory ? q : prevVal, items, idx + 1, href, isSearchHistory ? undefined : contentGroup, isSearchHistory ? 'history' : undefined);
                        }
                        callbacks.enterResult(q, href, ctrlKey, prevVal);
                    }
                    catch (ex) {
                        Logger.warn(ex);
                    }
                }
                if (!ctrlKey && (settings.setSearchBoxTextOnSelect || href === undefined)) {
                    _this.resetSuggests();
                    _this.hideSuggests(e);
                    _this.hideSpecialSuggest();
                }
            });
        });
        // Don't hide suggests on long click and on heading click
        this.selectables.mousedown(function () {
            _this.keepSuggests = true;
        });
        this.selectables.mouseup(function () {
            _this.keepSuggests = false;
        });
        selectablesScope.find('.unibox__suggest-heading').mousedown(function (e) {
            e.preventDefault(); // don't blur the search box
            e.stopPropagation();
            _this.keepSuggests = true;
        }).mouseup(function () {
            _this.keepSuggests = false;
        });
        if (data.words !== undefined && !this.shouldUseSpecialSuggestBox()) {
            var queryVisualizationHeadline = settings.queryVisualizationHeadline, instantVisualFeedback_1 = settings.instantVisualFeedback, ivfImagePath_1 = settings.ivfImagePath, ivfImageOffset_1 = settings.ivfImageOffset;
            // trigger words / visualization
            if (data.words.length > 0 && queryVisualizationHeadline.length > 0 && (instantVisualFeedback_1 === 'all' || instantVisualFeedback_1 === 'bottom')) {
                $suggestBox.append("<" + this.headingElement + ">" + queryVisualizationHeadline + "</" + this.headingElement + ">");
                showSuggestBox = true;
            }
            var newIvfWords_1 = [];
            data.words.forEach(function (word) {
                if ((instantVisualFeedback_1 === 'all' || instantVisualFeedback_1 === 'bottom')) {
                    if (word.overlayImage !== undefined && word.overlayImage !== null && word.overlayImage.length > 0) {
                        $suggestBox.append("<img  alt aria-hidden=\"true\" role=\"presentation\" class=\"unibox__vis\" src=\"" + ivfImagePath_1 + word.overlayImage + "\" \n\t\t\t\t\t\t\tstyle=\"background-image: url('" + ivfImagePath_1 + word.image + "');background-size: 75%;background-repeat: no-repeat;background-position: center;\">");
                    }
                    else if (word.image !== undefined && word.image !== null && word.image.length > 0) {
                        $suggestBox.append("<img  alt aria-hidden=\"true\" role=\"presentation\" class=\"unibox-vis\" src=\"" + ivfImagePath_1 + word.image + "\">");
                    }
                }
                var invisibleBox = _this.searchBoxParent.find("#" + _this.uniboxInvisibleId);
                invisibleBox.css('padding', _this.searchBox.css('padding'));
                invisibleBox.html(searchStringXss.replace(new RegExp(word.name, 'gi'), "<span>" + word.name + "</span>"));
                // show visuals above search bar
                if ((instantVisualFeedback_1 === 'all' || instantVisualFeedback_1 === 'top') && _this.ivfWords.indexOf(word.image) === -1) {
                    var span = _this.searchBoxParent.find("#" + _this.uniboxInvisibleId + " span").get()[0];
                    if (span !== undefined && word.name.length > 0 && word.image !== undefined && word.image != null && word.image.length > 0) {
                        var posLeft = sxQuery(span).position().left;
                        var visImage = sxQuery("<div class=\"unibox__ivf\"><img aria-hidden=\"true\" role=\"presentation\" src=\"" + ivfImagePath_1 + word.image + "\" alt=\"" + word.name + "\" class=\"unibox__ivf-img\"></div>");
                        visImage.css('left', _this.getSearchBoxOffset().left + posLeft - 10);
                        visImage.css('top', _this.getSearchBoxOffset().top - _this.searchBox.outerHeight() + ivfImageOffset_1);
                        _this.searchBoxParent.append(visImage);
                        setTimeout(function () {
                            _this.searchBoxParent.find('.unibox-ivf').find('img').addClass('unibox__ivf-img--l');
                        }, 10);
                        newIvfWords_1.push(word.image);
                    }
                }
                else if (_this.ivfWords.indexOf(word.image) > -1) {
                    newIvfWords_1.push(word.image);
                }
            });
            this.ivfWords = newIvfWords_1;
        }
        if (!this.suggestionsBelow() && !this.shouldUseSpecialSuggestBox()) {
            // add callback to image load to reposition the suggest box (otherwise slow loading images move the box down and it covers the search box)
            this.getSuggestBox().find('img').on('load', function () {
                _this.resizeAndReposition();
            });
        }
        else {
            // hide broken images
            this.getSuggestBox().find('img').each(function (item) {
                var src = item.src;
                var image = new Image();
                image.onerror = function () {
                    sxQuery(item).hide();
                };
                image.src = src;
            });
        }
        // // position it
        this.resizeAndReposition();
        if (settings.noSuggests !== undefined && !showSuggestBox) {
            showSuggestBox = true;
            showNoSuggestions = true;
            $suggestBox.append(settings.noSuggests);
        }
        var trackSuggestShown = function () {
            if (settings.trackingCallbacks.show !== undefined) {
                var queryDuration = querySubmitTimestamp !== undefined ? (new Date().getTime()) - querySubmitTimestamp : undefined;
                settings.trackingCallbacks.show(_this.getExistingSpecialSearchbox().hasClass('unibox-special__searchbox--active') ? _this.getExistingSpecialSearchbox().get()[0]
                    : _this.searchBox.get()[0], $suggestBox.get()[0], $suggestBox.find('.unibox__selectable').get()[0], searchString, $suggestBox.find('.unibox__selectable'), queryDuration);
            }
        };
        // // show it
        if (showSuggestBox) {
            this.searchBox.attr('aria-expanded', 'true');
            // if already visible, just update position and set class
            if ($suggestBox.isVisible()) {
                $suggestBox.addClass('unibox--active');
                // re-position it (in some cases the slide down moves the search box and the suggest box is not aligned anymore)
                this.resizeAndReposition();
            }
            else if (!this.shouldUseSpecialSuggestBox()) { // do nothing for mobile suggest box
                this.show($suggestBox, trackSuggestShown);
            }
            if (this.shouldUseSpecialSuggestBox()) {
                trackSuggestShown();
            }
            if (settings.showMoreResults && !showNoSuggestions) {
                $suggestBox.append(settings.showMoreResults);
            }
        }
        else {
            sxQuery("#" + this.uniboxStatusMessageId).text(accessibility.srNoSuggestionsText);
            this.resetSuggests();
            this.showSpecialSuggestPlaceholder();
            $suggestBox.addClass('unibox--empty');
        }
        // // indicate suggestions changed
        if (callbacks.suggestChange !== undefined && typeof callbacks.suggestChange === 'function') {
            callbacks.suggestChange(showSuggestBox);
        }
        this.suggestionsVisible = true;
    };
    UniBox.prototype.show = function ($suggestBox, trackingCallback) {
        var _this = this;
        if (trackingCallback === void 0) { trackingCallback = function () { }; }
        if (this.suggestionsBelow()) {
            // if suggestbox currently not visible, slide down
            $suggestBox.slideDown(this.settings.animationSpeed, function () {
                $suggestBox.addClass('unibox--active');
                // re-position it (in some cases the slide down moves the search box and the suggest box is not aligned anymore)
                _this.resizeAndReposition();
                trackingCallback();
            });
        }
        else {
            $suggestBox.css('display', 'block');
            $suggestBox.addClass('unibox--active');
            this.resizeAndReposition();
            trackingCallback();
        }
    };
    UniBox.prototype.getSearchBoxOffset = function () {
        return {
            left: this.searchBox.offset().left - this.searchBoxParent.offset().left,
            top: this.searchBox.offset().top - this.searchBoxParent.offset().top + this.searchBox.outerHeight()
        };
    };
    UniBox.prototype.updateIvf = function () {
        var shownWords = this.searchBoxParent.find('.unibox-ivf img').map(function (idx, el) { return sxQuery(el).attr('src'); });
        for (var i = 0; i < shownWords.length; i++) {
            if (this.ivfWords.indexOf(shownWords[i].replace(this.settings.ivfImagePath, '')) === -1) {
                this.searchBoxParent.find(".unibox-ivf img[src*=\"" + shownWords[i] + "\"]").remove();
            }
        }
    };
    UniBox.prototype.clearIvf = function () {
        this.ivfWords = [];
        this.searchBoxParent.find('.unibox-ivf').remove();
    };
    UniBox.prototype.scrollList = function (event) {
        var _this = this;
        this.savedKeyCodeOnKeyDown = event.keyCode || event.which;
        var searchBox = this.searchBox;
        var _a = this.settings, callbacks = _a.callbacks, trackingCallbacks = _a.trackingCallbacks, ignoreEnter = _a.ignoreEnter;
        if (searchBox.val().length <= 1) {
            this.clearIvf();
        }
        if (trackingCallbacks.change) {
            setTimeout(function () {
                trackingCallbacks.change(_this.getExistingSpecialSearchbox().hasClass('unibox-special__searchbox--active') ? _this.getExistingSpecialSearchbox().get()[0] : searchBox.get()[0]);
            }, 1);
        }
        if (callbacks.type !== undefined) {
            try {
                // @ts-ignore
                callbacks.type(event, searchBox.val());
            }
            catch (ex) {
                Logger.warn(ex);
            }
        }
        // return if NO arrow key is pressed
        if (event.keyCode !== 37 && event.keyCode !== 38 && event.keyCode !== 39 && event.keyCode !== 40 && event.keyCode !== 13) {
            this.updateIvf();
            return;
        }
        // if arrows are pressed move selected entry
        var dir = 0;
        if (event.keyCode === 38) {
            dir = -1;
        }
        else if (event.keyCode === 40) {
            dir = 1;
        }
        var $selectables = this.selectables;
        if ($selectables !== undefined) {
            $selectables.removeClass('unibox__selectable--active');
            // left/right key: move left/up or right/down one content group if we are in the selected entries (selectedEntryIndex > -1)
            if (dir === 0 && this.selectedEntryIndex > -1 && (event.keyCode === 37 || event.keyCode === 39)) {
                this.selectedEntryIndex %= $selectables.length;
                var currentSelection = sxQuery($selectables.get()[this.selectedEntryIndex]);
                var currentCluster = currentSelection.closest('.unibox__cluster');
                var otherCluster = void 0;
                if (event.keyCode === 37) {
                    otherCluster = currentCluster.prev();
                }
                else if (event.keyCode === 39) {
                    otherCluster = currentCluster.next();
                }
                if (otherCluster.hasClass('unibox__cluster')) {
                    var firstSelectableInCluster = otherCluster.find('.unibox__selectable').get()[0];
                    this.selectedEntryIndex = this.getSuggestBox().find('.unibox__selectable').index(firstSelectableInCluster);
                }
            }
            else if (dir !== 0) {
                this.selectedEntryIndex += dir;
                if (this.selectedEntryIndex < -1) {
                    this.selectedEntryIndex = $selectables.length - 1;
                }
                else if (this.selectedEntryIndex >= $selectables.length) {
                    this.selectedEntryIndex = -1;
                }
            }
            // mark the selected selectable
            if ($selectables.length > 0 && this.selectedEntryIndex > -1) {
                this.selectedEntryIndex %= $selectables.length;
                var selected = sxQuery($selectables.get()[this.selectedEntryIndex]);
                selected.addClass('unibox__selectable--active');
                $selectables.attr('aria-selected', 'false');
                $selectables.attr('id', '');
                selected.attr('id', 'unibox-active');
                selected.attr('aria-selected', 'true');
                if (selected.length > 0) {
                    var box = this.shouldUseSpecialSuggestBox() ? this.getExistingSpecialSearchbox() : searchBox;
                    box.attr('aria-activedescendant', 'unibox-active');
                }
            }
        }
        if (event.keyCode === 13 && !ignoreEnter) {
            event.preventDefault();
            event.stopPropagation();
            if (this.selectedEntryIndex !== -1) {
                var selectablesScope = this.shouldUseSpecialSuggestBox() ? this.getExistingUniboxSpecialWrap() : this.searchBoxParent;
                var activeSelectable = sxQuery(selectablesScope.find('.unibox__selectable--active').get()[0]);
                // @ts-ignore
                var selectedText = this.getSearchBox().val();
                if (activeSelectable.hasClass('unibox__selectable--group-cta')) {
                    // @ts-ignore
                    var contentGroup = activeSelectable.data('ss360Group');
                    this.onGroupCtaClick(event, selectedText, contentGroup);
                    return;
                }
                var href = getHref(activeSelectable);
                if (callbacks.enterResult !== undefined) {
                    var prevVal = selectedText;
                    if (activeSelectable.hasClass('unibox__selectable--show-all')) {
                        callbacks.enter(selectedText, activeSelectable.get()[0]);
                    }
                    else {
                        selectedText = getContent(activeSelectable);
                        this.getSearchBox().val(selectedText);
                        if (trackingCallbacks.select !== undefined) {
                            var sugBox = this.getSuggestBox();
                            var sField = this.getSearchBox();
                            var items = sugBox.find('.unibox__selectable');
                            trackingCallbacks.select(sField.get()[0], sugBox.get()[0], activeSelectable.get()[0], prevVal, items, SxQueryUtils.indexInNodeList(activeSelectable.get()[0], items.get()), href);
                        }
                        try {
                            callbacks.enterResult(selectedText, href, event.ctrlKey, prevVal);
                        }
                        catch (ex) {
                            Logger.warn(ex);
                        }
                    }
                }
                else {
                    window.location.href = href;
                    this.hideSpecialSuggest();
                }
            }
            return;
        }
        if (this.selectedEntryIndex > -1) {
            event.preventDefault();
        }
    };
    UniBox.prototype.searchSuggest = function (event) {
        var _this = this;
        // don't show suggests if alt + something is pressed
        if (this.lastKeyCode === 18) {
            this.lastKeyCode = event.keyCode;
            return;
        }
        this.lastKeyCode = event.keyCode;
        // if event keycode = -1 it means the box should just open, if we queried before, we do not need to update the box
        if (this.lastKeyCode === -1 && this.lastData !== undefined) {
            this.updateSuggestBox(this.lastData);
            return;
        }
        // do nothing at ESC
        if (event.keyCode === 27) {
            return;
        }
        // scroll list when up or down is pressed
        if (event.keyCode === 37 || event.keyCode === 39 || event.keyCode === 38 || event.keyCode === 40 || event.keyCode === 13 || event.keyCode === 9) {
            return;
        }
        // @ts-ignore
        var inputText = this.getSearchBox().val();
        if (this.lastData !== undefined && this.lastData.query === inputText) { // ignore arrow keys, etc.
            return;
        }
        if (this.lastData !== undefined && this.lastData.query === undefined && this.lastSubmittedQuery === inputText) { // sx product search doesn't suggestion query
            return;
        }
        if (this.lastSubmittedQuery !== undefined && inputText !== undefined && this.lastSubmittedQuery.trim() === inputText.trim()) { // just a space
            return;
        }
        if (this.lastKeyCode === 46 && inputText.length === 0) {
            this.clearIvf();
        }
        var _a = this.settings, callbacks = _a.callbacks, minChars = _a.minChars, suggestUrl = _a.suggestUrl, showOnMobile = _a.showOnMobile, triggersSearch = _a.triggersSearch;
        if (callbacks.preSuggest !== undefined) {
            var keepGoing = true;
            try {
                keepGoing = callbacks.preSuggest(inputText, this.searchBox.get()[0]);
            }
            catch (ex) {
                Logger.warn(ex);
            }
            if (!keepGoing) {
                return;
            }
        }
        if (inputText.length >= minChars && suggestUrl !== '' && (showOnMobile || SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Min, 768))) {
            if (callbacks.loading !== undefined && (typeof callbacks.loading) === 'function') {
                // @ts-ignore
                callbacks.loading(event);
            }
            this.currentInput = inputText;
            var lInputText_1 = inputText;
            if (!triggersSearch) {
                var submitTimestamp_1 = new Date().getTime();
                SxQueryUtils.ajax({
                    url: suggestUrl + encodeURIComponent(inputText),
                    dataType: 'json',
                    success: function (data) {
                        if (lInputText_1 === _this.currentInput) {
                            if (callbacks.loaded !== undefined && (typeof callbacks.loaded) === 'function') {
                                callbacks.loaded(_this, data);
                            }
                            _this.updateSuggestBox(data, submitTimestamp_1);
                        }
                        _this.lastData = data;
                        _this.lastSubmittedQuery = inputText;
                    }
                }, true, true);
            }
            else if (callbacks.enter !== undefined) {
                callbacks.enter(lInputText_1, this.getSearchBox().get()[0]);
            }
        }
        else {
            this.showSpecialSuggestPlaceholder();
        }
    };
    UniBox.prototype.suggestionsBelow = function () {
        if (this.settings.forceBelow) {
            return true;
        }
        if (this.settings.mobileScrollOnFocus && SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Min, 767)) {
            return true;
        }
        var bb = this.searchBox.get()[0].getBoundingClientRect();
        var vph = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        var spaceAbove = bb.y || bb.top;
        var spaceBelow = vph - spaceAbove - bb.height;
        return spaceBelow >= spaceAbove;
    };
    UniBox.prototype.suggestionsLeft = function (sbWidth) {
        var bb = this.searchBox.get()[0].getBoundingClientRect();
        var w = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        return ((bb.x || bb.left) + sbWidth) > w;
    };
    UniBox.prototype.resizeAndReposition = function () {
        var $suggestBox = this.getExistingUnibox();
        if (this.isFullWidth()) {
            $suggestBox.css('left', 0);
            $suggestBox.css('max-width', '100%');
            $suggestBox.css('min-width', '100%');
            $suggestBox.css('width', '100%');
            $suggestBox.css('top', this.getSearchBoxOffset().top);
            return;
        }
        var maxWidth = this.settings.maxWidth;
        var searchBox = this.searchBox;
        // @ts-ignore
        var borderSize = parseInt(($suggestBox.css('border-width') || '0px').replace('px', ''), 10);
        var hasAutoWidth = maxWidth === 'auto' || !parseInt(maxWidth.toString(), 10);
        // @ts-ignore
        var minWidth = hasAutoWidth ? searchBox.outerWidth() - 2 * borderSize : maxWidth - 2 * borderSize;
        var searchButtonWidth = 0;
        if (this.searchButton !== undefined) {
            // @ts-ignore
            var buttonBorderWidth = parseInt((this.searchButton.css('borderWidth') || '0px').replace('px', ''), 10);
            searchButtonWidth = Math.min(this.searchButton.outerWidth(), 80) - buttonBorderWidth; // substract border just once, one border should be just next to the search field
        }
        var uniboxMaxWidth;
        if (hasAutoWidth) {
            minWidth += searchButtonWidth;
            uniboxMaxWidth = Math.max(275 - 2 * borderSize, searchBox.outerWidth() - 2 * borderSize + searchButtonWidth);
        }
        else {
            uniboxMaxWidth = parseInt(maxWidth.toString(), 10) - 2 * borderSize;
        }
        $suggestBox.css('min-width', minWidth);
        $suggestBox.css('max-width', uniboxMaxWidth);
        if (this.suggestionsLeft(uniboxMaxWidth)) {
            var bb = searchBox.get()[0].getBoundingClientRect();
            var r = (bb.x || bb.left) + bb.width;
            var l = r - uniboxMaxWidth;
            if (searchBox.parent().css('position') === 'relative') {
                var pBB = searchBox.parent().get()[0].getBoundingClientRect();
                l -= (pBB.x || pBB.left);
                // would go out of the client? --> move to the right
                var wo = (pBB.x || pBB.left) + l;
                if (l < 0 && wo < 0) {
                    l -= wo;
                }
            }
            else {
                l = Math.max(0, l);
            }
            $suggestBox.css('left', l);
        }
        else {
            $suggestBox.css('left', this.getSearchBoxOffset().left);
        }
        if (this.suggestionsBelow()) {
            $suggestBox.css('top', this.getSearchBoxOffset().top);
        }
        else {
            $suggestBox.css('top', this.getSearchBoxOffset().top - $suggestBox.outerHeight() - searchBox.outerHeight());
        }
        this.resizeSpecialSuggestBox();
    };
    UniBox.prototype.shouldUseSpecialSuggestBox = function () {
        var specialMobileSuggest = this.settings.specialMobileSuggest;
        var matchesBreakpoint = SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Max, specialMobileSuggest.breakpoint);
        var isSpecialActive = this.getExistingSpecialSearchbox().hasClass('unibox-special__searchbox--active'); // check for active class to stay in context after window has been resized
        return specialMobileSuggest.enabled && (matchesBreakpoint || isSpecialActive);
    };
    UniBox.prototype.resizeSpecialSuggestBox = function () {
        var uniboxSpecial = this.getExistingUniboxSpecialWrap();
        var specialSuggestBox = this.getExistingSpecialUnibox();
        if (uniboxSpecial && specialSuggestBox) {
            // @ts-ignore
            var uniboxLogoHeight = this.hasSpecialLogoTemplate() ? uniboxSpecial.find('.unibox-special-logo').height() : 0;
            // @ts-ignore
            var inputContainerHeight = uniboxSpecial.find('.input-container').height();
            var heightSum = uniboxLogoHeight + inputContainerHeight;
            var suggestHeight = "calc(100% - " + heightSum + "px)";
            specialSuggestBox.css('height', suggestHeight);
            specialSuggestBox.css('top', heightSum + "px");
        }
    };
    UniBox.prototype.initSpecialSuggestBox = function () {
        var _this = this;
        var specialMobileSuggests = this.settings.specialMobileSuggest;
        if (!specialMobileSuggests.enabled || this.getExistingSpecialUnibox().length > 0) {
            return;
        }
        var currentQuery = StringHelper.escapeHtml(this.searchBox.val());
        var inputFieldString = "<input type=\"search\" id=\"" + this.specialSearchBoxId + "\" class=\"unibox-special__searchbox unibox-n-input\" value=\"" + currentQuery + "\"\n            autocomplete=\"off\" role=\"combobox\" aria-describedby=\"" + this.uniboxControlsId + "\" aria-owns=\"" + this.specialId + "\" aria-controls=\"" + this.specialId + "\" \n            aria-expanded=\"false\"/>";
        var specialSuggestBoxString = "<div id=\"" + this.specialId + "\" class=\"unibox unibox--special\">" + specialMobileSuggests.placeholder + "</div>";
        var searchButtonString = '<button class="unibox-special__icon unibox-special__icon--searchbutton unibox-n-button" aria-label="Search"></button>';
        var closeButtonString = '<button class="unibox-special__icon unibox-special__icon--close unibox-n-button" aria-label="Close"></button>';
        var uniboxSpecial = sxQuery("<section role=\"search\" id=\"" + this.specialUniboxWrapId + "\" class=\"unibox-special unibox-n-section\" style=\"display: none;\"></section>");
        var inputContainer = sxQuery('<section class="unibox-special__input-wrap unibox-n-section"></section>');
        var searchFieldLabel = this.settings.accessibility.searchFieldLabel;
        if (searchFieldLabel) {
            var labelFieldString = "<label style='" + SxQueryUtils.srOnlyCss + "' class=\"unibox-sr-only\" for=\"" + this.specialSearchBoxId + "\">" + searchFieldLabel + "</label>";
            inputContainer.append(sxQuery(labelFieldString));
        }
        var closeButton = sxQuery(closeButtonString);
        inputContainer.append(closeButton);
        var specialSearchBox = sxQuery(inputFieldString);
        // @ts-ignore
        var specialPlaceholder = this.searchBox.attr('placeholder') || specialMobileSuggests.searchBoxPlaceholder;
        specialSearchBox.attr('placeholder', specialPlaceholder);
        inputContainer.append(specialSearchBox);
        var specialSearchButton = sxQuery(searchButtonString);
        inputContainer.append(specialSearchButton);
        var specialSuggestBox = sxQuery(specialSuggestBoxString);
        if (this.hasSpecialLogoTemplate()) {
            uniboxSpecial.append("<div class=\"unibox-special-logo\">" + specialMobileSuggests.customTopHtml + "</div>");
        }
        uniboxSpecial.append(inputContainer);
        uniboxSpecial.append(specialSuggestBox);
        // prepend special mobile suggest box to body
        var body = sxQuery('body');
        body.prepend(uniboxSpecial);
        body.append("<div id=\"" + this.specialUniboxHiddenContentId + "\" style=\"overflow: hidden;\"></div>");
        // bind listeners
        specialSearchBox.keydown(function (event) {
            _this.throttle(function () {
                _this.searchSuggest(event);
            }, _this.settings.throttleTime);
        });
        specialSearchBox.keydown(function (event) {
            _this.scrollList(event);
        });
        // to update suggestions after search event is triggered (especially on delete button in input[type='search']
        specialSearchBox.on('search', function (e) {
            _this.searchSuggest(e);
        });
        var enterCallback = this.settings.callbacks.enter;
        var autoHide = specialMobileSuggests.autoHide;
        if (enterCallback) {
            specialSearchButton.on('click', function () {
                var query = specialSearchBox.val() || '';
                var loaderSelector = _this.settings.loaderSelector;
                if (loaderSelector !== undefined && autoHide === false) {
                    sxQuery(_this.settings.loaderSelector).css('z-index', '9999999');
                }
                enterCallback.call(_this, query, specialSearchButton.get()[0], specialMobileSuggests.autoHide === false ? function () {
                    _this.hideSpecialSuggest(function () {
                        if (loaderSelector !== undefined) {
                            sxQuery(loaderSelector).css('z-index', '');
                        }
                    });
                } : undefined);
                if (autoHide !== false) {
                    _this.hideSpecialSuggest();
                }
            });
        }
        else if (autoHide !== false) {
            specialSearchButton.on('click', function () {
                _this.hideSpecialSuggest();
            });
        }
        specialSearchBox.keyup(function (event) {
            _this.resetSuggests(event);
        });
        specialSearchBox.keyup(function (e) {
            var keyCode = e.keyCode || e.which;
            // hide on esc
            if (keyCode === 27) {
                _this.hideSpecialSuggest();
            }
        });
        closeButton.on('click', function () {
            _this.hideSpecialSuggest(undefined, closeButton);
        });
        // init scroll callback to resize the input container when user scrolls
        if (specialMobileSuggests.resizeSearchBoxOnScroll) {
            // resize input elements on scroll
            specialSuggestBox.scroll(function (e) {
                var scrollTop = e.target.scrollTop;
                var resizeFactor = (scrollTop / 100) / 2; // 200px for complete transition
                if (resizeFactor > 1 || resizeFactor < 0) {
                    return;
                }
                // simulate slightly smoother scroll feeling (especially for slow scroll)
                resizeFactor = Math.log1p(resizeFactor);
                var maxResizeFactorValue = Math.log1p(1);
                resizeFactor *= 1 / maxResizeFactorValue;
                _this.resizeSpecialInputField(resizeFactor);
                _this.resizeSpecialSuggestBox();
            });
        }
        // element for transition animation
        if (specialMobileSuggests.animateTransitions) {
            // @ts-ignore
            var animationDuration = parseFloat(this.settings.animationSpeed);
            var existingBackground = sxQuery("#" + this.specialUniboxBackgroundId);
            var specialMobileSuggestAnimationElem = existingBackground.length > 0 ? existingBackground
                : sxQuery("<div id=\"" + this.specialUniboxBackgroundId + "\" style=\"background: #fff; position: fixed; width: 100%; height: 100%; z-index: 1000001; left: 100%; top: 0; display: none;\" \n\t\t\t\t\tclass=\"unibox-special__background\"></div>");
            specialMobileSuggestAnimationElem.css('transition', "transform " + animationDuration + "ms");
            if (existingBackground.length === 0) {
                sxQuery('body').append(specialMobileSuggestAnimationElem);
            }
        }
    };
    UniBox.prototype.resizeSpecialInputField = function (resizeFactor) {
        // calculate new special search box height and font size
        var specialSearchBox = this.getExistingSpecialSearchbox();
        var uniboxSpecial = this.getExistingUniboxSpecialWrap();
        var specialScrollSettings = this.specialScrollSettings;
        var maxHeight = specialScrollSettings.box.height;
        var fontSizeProportion = specialScrollSettings.box.fontSize / maxHeight;
        var minHeight = 32;
        var heightDif = maxHeight - minHeight;
        var effectiveHeightDif = heightDif * resizeFactor;
        var newHeight = Math.round(maxHeight - effectiveHeightDif);
        var newFontSize = Math.round(fontSizeProportion * newHeight);
        specialSearchBox.css('height', newHeight);
        specialSearchBox.css('font-size', newFontSize);
        // calculate icon dimension
        var maxWidthIcon = specialScrollSettings.icons.width;
        var maxHeightIcon = specialScrollSettings.icons.height;
        var minDimensionIcon = minHeight + 2;
        var widthDifIcon = maxWidthIcon - minDimensionIcon;
        var heightDifIcon = maxHeightIcon - minDimensionIcon;
        var effectiveWidthDifIcon = widthDifIcon * resizeFactor;
        var effectiveHeightDifIcon = heightDifIcon * resizeFactor;
        var newWidthIcon = Math.round(maxWidthIcon - effectiveWidthDifIcon);
        var newHeightIcon = Math.round(maxHeightIcon - effectiveHeightDifIcon);
        var icons = uniboxSpecial.find('.unibox-special__icon');
        icons.css('height', newHeightIcon);
        icons.css('width', newWidthIcon);
        // calculate new special search box width and margins
        var defaultMargin = specialScrollSettings.box.marginLeft;
        var newMargin = defaultMargin - (maxWidthIcon - newWidthIcon);
        var newWidht = "calc(100% - 2*" + newMargin + "px)";
        specialSearchBox.css('width', newWidht);
        specialSearchBox.css('margin-left', newMargin);
        specialSearchBox.css('margin-right', newMargin);
    };
    // show special mobile suggest box
    UniBox.prototype.showSpecialSuggest = function () {
        var _this = this;
        var specialMobileSuggest = this.settings.specialMobileSuggest;
        if (!specialMobileSuggest.enabled) {
            return;
        }
        if (this.shouldPreventIosBounce()) {
            this.registerIosBouncePreventer();
        }
        var uniboxSpecial = this.getExistingUniboxSpecialWrap();
        if (!uniboxSpecial || uniboxSpecial.length === 0) {
            this.initSpecialSuggestBox();
            uniboxSpecial = this.getExistingUniboxSpecialWrap();
        }
        // iPhone 5 fix
        uniboxSpecial.get()[0].scrollTop = 0;
        var specialSearchBox = this.getExistingSpecialSearchbox();
        specialSearchBox.addClass('unibox-special__searchbox--active');
        specialSearchBox.attr('aria-expanded', 'true');
        if (specialSearchBox.val() === '') {
            this.getExistingSpecialUnibox().html('');
        }
        var resizeCallback = function () {
            uniboxSpecial.get()[0].scrollTop = 0;
            // fix layout if logo template is being shown
            var icons = uniboxSpecial.find('.unibox-special__icon');
            if (_this.hasSpecialLogoTemplate()) {
                // @ts-ignore
                var uniboxLogoHeight = uniboxSpecial.find('.unibox-special-logo').height();
                // @ts-ignore
                var iconMarginTop = uniboxLogoHeight + parseFloat(specialSearchBox.css('margin-top') || '0');
                icons.css('top', iconMarginTop);
            }
            // reset scroll behavior
            if (specialMobileSuggest.resizeSearchBoxOnScroll) {
                icons.css('width', '');
                icons.css('height', '');
                specialSearchBox.css('height', '');
                specialSearchBox.css('width', '');
                specialSearchBox.css('margin-left', '');
                specialSearchBox.css('margin-right', '');
                specialSearchBox.css('font-size', '');
                _this.getExistingSpecialUnibox().scrollTop(0);
                // read and save initial scroll settings
                _this.specialScrollSettings = {
                    icons: {
                        // @ts-ignore
                        width: parseFloat(icons.css('width')),
                        // @ts-ignore
                        height: parseFloat(icons.css('height'))
                    },
                    box: {
                        // @ts-ignore
                        height: parseFloat(specialSearchBox.css('height')),
                        // @ts-ignore
                        marginLeft: parseFloat(specialSearchBox.css('margin-left')),
                        // @ts-ignore
                        fontSize: parseFloat(specialSearchBox.css('font-size'))
                    }
                };
            }
        };
        // hide all visible content except of suggestions
        var bodyElems = sxQuery("#" + this.specialUniboxWrapId + " ~ *:not(#" + this.specialUniboxBackgroundId + "):not(#" + this.specialUniboxHiddenContentId + "):not(.ss360-sr-only):not(.unibox-sr-only)");
        bodyElems = bodyElems.filter(function (el) { return sxQuery(el).isVisible(); });
        var hiddenContainer = sxQuery("#" + this.specialUniboxHiddenContentId);
        var body = sxQuery('body');
        body.append(hiddenContainer);
        hiddenContainer.append(bodyElems);
        // make sure the html and body element do have full height
        sxQuery('html, body').addClass('unibox-stretch');
        // run blend-in animation
        var shownCallback = specialMobileSuggest.shownCallback;
        if (specialMobileSuggest.animateTransitions) {
            this.animateSpecial(hiddenContainer, uniboxSpecial, function () {
                resizeCallback();
            }, function () {
                specialSearchBox.focus();
                if (shownCallback !== undefined) {
                    specialMobileSuggest.shownCallback();
                }
            });
        }
        else {
            hiddenContainer.hide();
            uniboxSpecial.show();
            specialSearchBox.focus();
            resizeCallback();
            if (shownCallback !== undefined) {
                shownCallback();
            }
        }
    };
    UniBox.prototype.hideSpecialSuggest = function (callback, closeTrigger) {
        var _this = this;
        if (!this.shouldUseSpecialSuggestBox()) {
            return;
        }
        if (this.shouldPreventIosBounce()) {
            this.removeIosBouncePreventer();
        }
        var specialSearchBox = this.getExistingSpecialSearchbox();
        var trackingCallbacks = this.settings.trackingCallbacks;
        if (trackingCallbacks.abandon !== undefined && closeTrigger !== undefined && closeTrigger.hasClass('unibox-special__icon--close')) {
            // @ts-ignore
            trackingCallbacks.abandon(specialSearchBox.val(), this.getExistingUniboxSpecialWrap().find('.unibox__selectable').length, specialSearchBox.get()[0]);
        }
        this.getExistingUniboxSpecialWrap().hide();
        var wasSpecialUsed = specialSearchBox.hasClass('unibox-special__searchbox--active');
        specialSearchBox.removeClass('unibox-special__searchbox--active');
        specialSearchBox.attr('aria-expanded', 'false');
        specialSearchBox.removeAttribute('aria-activedescendant');
        var hiddenContainer = sxQuery("#" + this.specialUniboxHiddenContentId);
        var bodyElems = hiddenContainer.children();
        sxQuery('body').append(bodyElems);
        if (wasSpecialUsed) {
            this.syncSearchBoxQuery();
        }
        var callbackWrapper = function (innerCbc) {
            // remove changes from body and html element
            sxQuery('html, body').removeClass('unibox-stretch');
            if (innerCbc !== undefined && typeof innerCbc === 'function') {
                innerCbc();
            }
            var hiddenCallback = _this.settings.specialMobileSuggest.hiddenCallback;
            if (hiddenCallback !== undefined && typeof hiddenCallback === 'function') {
                hiddenCallback();
            }
        };
        // run animation
        if (this.settings.specialMobileSuggest.animateTransitions) {
            this.animateSpecial(this.getExistingUniboxSpecialWrap(), sxQuery("#" + this.specialUniboxWrapId + " ~ *:not(#" + this.specialUniboxBackgroundId + ")").filter(function (el) { return sxQuery(el).isVisible(); }), undefined, function () {
                callbackWrapper(callback);
            });
        }
        else {
            callbackWrapper(callback);
        }
    };
    UniBox.prototype.shouldPreventIosBounce = function () {
        if (this.shouldIosBounceBePrevented === undefined) {
            var elem = sxQuery("<div id='ios-bounce-test' style='-webkit-overflow-scrolling: touch;'></div>");
            sxQuery('body').append(elem);
            this.shouldIosBounceBePrevented = !!elem.css('-webkit-overflow-scrolling');
            elem.remove();
        }
        return this.shouldIosBounceBePrevented;
    };
    UniBox.prototype.registerIosBouncePreventer = function () {
        var _this = this;
        var eventNamePostfix = this.settings.context !== undefined ? this.settings.context.generateId('iosPreventer') : 'iosPreventer';
        sxQuery(window).on("touchstart." + eventNamePostfix, function (e) {
            // @ts-ignore
            var y = e.touches ? e.touches[0].screenY : e.screenY;
            if (!y && window.event) {
                // @ts-ignore
                y = window.event.touches ? window.event.touches[0].screenY : window.event.screenY;
            }
            sxQuery(window).on("touchmove." + eventNamePostfix, function () {
                _this.preventIosBounce(y, e);
            });
            sxQuery(window).on("touchend." + eventNamePostfix, function () {
                sxQuery(window).off("touchmove." + eventNamePostfix + ",touchend." + eventNamePostfix);
            });
        });
    };
    UniBox.prototype.removeIosBouncePreventer = function () {
        var eventNamePostfix = this.settings.context !== undefined ? this.settings.context.generateId('iosPreventer') : 'iosPreventer';
        sxQuery(window).off("touchstart." + eventNamePostfix + ", touchmove." + eventNamePostfix + ", touchend." + eventNamePostfix);
    };
    // prevent iOS Safari bounce effect, which tends to break mobile suggestions scrolling by moving the whole viewport
    // eslint-disable-next-line class-methods-use-this
    UniBox.prototype.preventIosBounce = function (yStart, e) {
        sxQuery(window).off('touchmove.iosPreventer'); // we only need to prevent the bounce once after touchstart --> performance
        // @ts-ignore
        var target = e.target || window.event.target;
        // get touch start position
        // @ts-ignore
        var yNow = e.touches ? e.touches[0].screenY : e.screenY;
        if (!yNow && window.event) {
            // @ts-ignore
            yNow = window.event.touches ? window.event.touches[0].screenY : window.event.screenY;
        }
        // loop through all DOM parents of touchemove target until scrollable element is found
        while (target !== document.body) {
            var $target = sxQuery(target);
            var overflowY = $target.css('overflow-y');
            // check whether the target is scrollable and has enough height to be scrolled
            var isScrollable = $target.css('-webkit-overflow-scrolling') === 'touch' && (overflowY === 'auto' || overflowY === 'scroll');
            var canScroll = target.scrollHeight > target.offsetHeight;
            if (isScrollable && canScroll) {
                if (yStart <= yNow && target.scrollTop === 0) { // prevent bounce - the element which is completely scrolled to top is tried to be scrolled up
                    e.preventDefault();
                }
                else {
                    var height = $target.height();
                    // @ts-ignore
                    if (yStart >= yNow && target.scrollHeight - target.scrollTop === height) { // prevent bounce  - the element which is completely scrolled to bottom is tried to be scrolled upwards
                        e.preventDefault();
                    }
                }
                return; // allow scroll
            }
            // @ts-ignore
            target = target.parentNode; // move up the DOM
        }
        // no scrollable element was found, prevent bounce
        e.preventDefault();
    };
    UniBox.prototype.animateSpecial = function (elemToHide, elemToShow, afterElemShownCallback, finishCallback) {
        var backgroundElem = sxQuery("#" + this.specialUniboxBackgroundId);
        // start with content to be shown hidden and content to be hidden visible
        elemToShow.hide();
        elemToHide.show();
        backgroundElem.show();
        var animationOffset = 140;
        // animate blend-in (move animation element to the left)
        setTimeout((function () {
            backgroundElem.addClass('unibox-special__background--left');
        }), animationOffset); // this little offset helps to boost animation performance
        // @ts-ignore
        var animationSpeed = parseFloat(this.settings.animationSpeed);
        // move animation element back to the right and show the content
        setTimeout(function () {
            elemToHide.hide();
            elemToShow.show();
            // the element was shown, notify this
            if (afterElemShownCallback) {
                afterElemShownCallback();
            }
            backgroundElem.removeClass('unibox-special__background--left');
            backgroundElem.addClass('unibox-special__background--right');
            // notify animation is completed
            setTimeout(function () {
                backgroundElem.hide();
                backgroundElem.removeClass('unibox-special__background--left');
                backgroundElem.removeClass('unibox-special__background--right');
                if (finishCallback !== undefined && typeof finishCallback === 'function') {
                    finishCallback();
                }
            }, animationSpeed);
        }, animationSpeed + animationOffset);
    };
    UniBox.prototype.syncSearchBoxQuery = function () {
        var specialSearchBox = this.getExistingSpecialSearchbox();
        if (specialSearchBox && specialSearchBox.length > 0) {
            // @ts-ignore
            this.searchBox.val(specialSearchBox.val());
        }
    };
    UniBox.prototype.syncSpecialSearchBoxQuery = function () {
        var specialSearchBox = this.getExistingSpecialSearchbox();
        // @ts-ignore
        specialSearchBox.val(this.searchBox.val());
    };
    UniBox.prototype.showSpecialSuggestPlaceholder = function () {
        if (this.shouldUseSpecialSuggestBox()) {
            this.getExistingSpecialUnibox().html(this.settings.specialMobileSuggest.placeholder);
        }
    };
    UniBox.prototype.hasSpecialLogoTemplate = function () {
        var htmlTemplate = this.settings.specialMobileSuggest.customTopHtml;
        return htmlTemplate && htmlTemplate.length > 0;
    };
    UniBox.prototype.isFullWidth = function () {
        return !this.shouldUseSpecialSuggestBox() && this.settings.mobileScrollOnFocus && SxQueryUtils.matchesMediaQuery(MediaQueryMatchType.Max, 767);
    };
    UniBox.prototype.updateSuggests = function (data) {
        this.updateSuggestBox(data);
    };
    UniBox.prototype.updateSuggestUrl = function (newUrl) {
        this.settings.suggestUrl = newUrl;
    };
    UniBox.prototype.hideSuggestBox = function () {
        this.resetSuggests();
        this.hideSpecialSuggest();
    };
    UniBox.prototype.setIvfImagePath = function (path) {
        if (path.charAt(path.length - 1) !== '/') {
            path += '/';
        }
        this.settings.ivfImagePath = path;
    };
    UniBox.prototype.changeInstantVisualFeedbackState = function (state) {
        this.settings.instantVisualFeedback = state;
    };
    UniBox.prototype.render = function () {
        this.resizeAndReposition();
    };
    UniBox.prototype.getText = function () {
        // @ts-ignore
        return this.getSearchBox().val();
    };
    UniBox.prototype.getOriginalSearchBox = function () {
        return this.searchBox;
    };
    return UniBox;
}());
export default UniBox;
